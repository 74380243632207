/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, ogimage }) {
  const { site, baseImg } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
        baseImg: file(
          absolutePath: { glob: "**/src/images/bike_couple_horiz.png" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 1200)
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  let fullTitle = `${title} | Multnomah Medical Clinic`
  if (fullTitle.length > 60 && fullTitle.indexOf("Multnomah") === -1) {
    fullTitle = `${title} | Multnomah Medical`
  }

  if (fullTitle.length > 60 || fullTitle.indexOf("Multnomah") !== -1) {
    fullTitle = `${title}`
  }

  let metaArr = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:image`,
      content:
        "https://multnomahmedical.com" +
        baseImg?.childImageSharp.gatsbyImageData.images.fallback.src,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: site.siteMetadata.author,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
  ].concat(meta)

  if (ogimage) {
    metaArr = [
      {
        name: `description`,
        content: metaDescription,
      },
      {
        property: `og:title`,
        content: title,
      },
      {
        property: `og:description`,
        content: metaDescription,
      },
      {
        property: `og:type`,
        content: `website`,
      },
      {
        property: `og:image`,
        content: ogimage,
      },
      {
        name: `twitter:card`,
        content: `summary`,
      },
      {
        name: `twitter:creator`,
        content: site.siteMetadata.author,
      },
      {
        name: `twitter:title`,
        content: title,
      },
      {
        name: `twitter:description`,
        content: metaDescription,
      },
    ].concat(meta)
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={fullTitle}
      titleTemplate={fullTitle}
      meta={metaArr}
    >
      {/*
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap" rel="stylesheet" />
      */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "http://schema.org",
          "@type": "LocalBusiness",
          currenciesAccepted: "USD",
          openingHours: "Mo,Tu,We,Th,Fr 8:00-18:00",
          paymentAccepted: "Cash, Credit Card",
          address: {
            "@type": "PostalAddress",
            addressCountry: "USA",
            addressLocality: "Happy Valley",
            addressRegion: "Oregon",
            postalCode: "97086",
            streetAddress: "9200 SE 91st Ave Suite 300",
          },
          email: "admin@multnomahmedical.com",
          legalName: "Multnomah Medical Clinic",
          telephone: "+15037664338",
        })}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
